import React, { useMemo } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useQuery } from '@apollo/react-hooks';
import { FieldArray, useFormikContext } from 'formik';
import { UploadFilesListItem } from './UploadFilesListItem';
import { FileCategories, FileCategoriesVariables } from '../../../api/types/FileCategories';
import { FILE_CATEGORIES } from '../queries/categories';
import { Order } from '../../../api/types/globalTypes';
import { ErrorSnackbar, useErrorSnackbar } from '../../notifications/ErrorSnackbar';
import { DocumentRepositoryForm } from '../useDocumentRepository';

interface UploadFilesListProps {
  categoriesFor?: 'repository' | 'eContract',
  allowAddToRepository?: boolean;
  allowSetPublic?: boolean;
}

export const UploadFilesList: React.FC<UploadFilesListProps> = ({
  categoriesFor,
  allowAddToRepository = false,
  allowSetPublic,
}) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<DocumentRepositoryForm>();
  const {
    data: categoriesData,
    error: categoriesError,
  } = useQuery<FileCategories, FileCategoriesVariables>(FILE_CATEGORIES, {
    variables: {
      where: {
        ...(categoriesFor === 'eContract' ? {
          apply_to_e_contract_files: { eq: 1 },
        } : {
          apply_to_repository_files: { eq: 1 },
        }),
      },
      order: [{ field: 'rank', order: Order.ASC }, { field: 'name', order: Order.ASC }],
    },
    fetchPolicy: 'cache-and-network',
  });

  const categoryErrorSnackbar = useErrorSnackbar(categoriesError);

  const categoriesOptions = useMemo(() => (
    categoriesData?._fileCategories.items
      .map((c) => ({ text: c.name, value: c.category_id })) ?? []
  ), [categoriesData]);

  return (
    <>
      <Grid container spacing={2}>
        <FieldArray name="files">
          {(arrayHelpers) => (
            <>
              {values.files.map((file, index) => (
                <Grid key={file.key} item xs={12} sm={6} md={4}>
                  <UploadFilesListItem
                    index={index}
                    onRemove={() => {
                      arrayHelpers.remove(index);
                    }}
                    disabled={isSubmitting}
                    categoryOptions={categoriesOptions}
                    item={file}
                  />
                  {allowAddToRepository && (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={!!values.files[index].show_in_repository}
                          onChange={(e) => {
                            setFieldValue(`files[${index}].show_in_repository`, e.target.checked, false);
                          }}
                          color="primary"
                        />
                      )}
                      label="Save To Library"
                    />
                  )}
                  {allowSetPublic && (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={values.files[index].is_public}
                          onChange={(e) => {
                            setFieldValue(`files[${index}].is_public`, e.target.checked, false);
                          }}
                          color="primary"
                        />
                      )}
                      label="Show in organization profile"
                    />
                  )}
                </Grid>
              ))}
            </>
          )}
        </FieldArray>
      </Grid>
      <ErrorSnackbar {...categoryErrorSnackbar}>
        Unable to retrieve file categories
      </ErrorSnackbar>
    </>
  );
};
