import { gql } from '@apollo/client';

export const IS_SP_EMAIL_VISIBLE_QUERY = 'IsSpEmailVisible';
export const IS_SP_EMAIL_VISIBLE = gql`
  query IsSpEmailVisible(
    $organizationId: AnyScalar!
  ) {
    fmBids(
      where: {
        and: [
          { organization_id: { eq: $organizationId } }
          { status_id: { in: [2, 4, 8, 20, 200, 201] } }
        ]
      }
    ) {
      total_count
    }
  }
`;
