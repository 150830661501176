import { gql } from '@apollo/client';

export enum FileCategories {
  CertificateOfInsurance = 1,
  SitePicture = 2,
  SOW = 3,
  License = 4,
  Contract = 5,
  Other = 6,
  W9 = 7,
  PreScreenQuestions = 8,
  SignedContract = 20,
}

export const FILE_CATEGORIES = gql`
query FileCategories($where: WhereFileCategoryInput, $order: [OrderInput!]) {
 _fileCategories(where: $where, order: $order) {
    items {
        category_id
        name
    }
    total_count
    has_more
  }
}
`;
