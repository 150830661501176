import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { omit } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { FormikProps } from 'formik/dist/types';
import { ApolloError } from '@apollo/client';
import { FileType, FileUploadContext, FileUploadInput } from '../../api/types/globalTypes';
import { FILES_QUERY } from './queries/files';
import { UploadFiles, UploadFiles_uploadFiles, UploadFilesVariables } from '../../api/types/UploadFiles';
import { UPLOAD_FILES } from './queries/uploadFiles';
import { Files__organizationFiles_items } from '../../api/types/Files';
import { BOARDING_COMPLETENESS_QUERY } from '../organization/sp/onboarding/queries/BoardingCompletenessQuery';

export interface FileItem extends Pick<FileUploadInput, 'from_file_id' | 'category_id' | 'name' | 'show_in_repository'> {
  key: string;
  type: FileType;
  file: File;
  is_public: boolean;
  rejectionReason?: string | null;
}

export interface DocumentRepositoryForm {
  files: FileItem[];
  selectedFiles: Files__organizationFiles_items[];
  // Hide category by default, but show if file has to be added to file repository
  categoryInitiallyHidden?: boolean;
}

const validationSchema = Yup.object().shape({
  files: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Name is required'),
    category_id: Yup.number().required('Category is required'),
  })),
});

export type DocumentRepositoryMode = 'selection' | 'standalone' | 'integrated';

export interface UseDocumentRepositoryHookProps {
  mode: DocumentRepositoryMode;
  allowToAddDocusignTemplates?: boolean;
  uploadContext?: FileUploadContext;
  refetchQueries?: string[];
  onFilesUploaded?: (files: UploadFiles_uploadFiles[]) => void;
  onChange?: (files: FileItem[]) => void;
}

export interface UseDocumentRepositoryHook<Values = DocumentRepositoryForm> {
  mode: DocumentRepositoryMode;
  allowToAddDocusignTemplates?: boolean;
  form: FormikProps<Values>;
  uploadContext?: FileUploadContext;
  uploadFiles: (
    values: Values, uploadContext?: FileUploadContext, refetchQueries?: string[]
  ) => Promise<UploadFiles_uploadFiles[]>;
  totalSelectedFiles: number;
  hasRejectedFiles: boolean;
  uploadError?: ApolloError;
}

export const useDocumentRepository = ({
  onFilesUploaded,
  uploadContext,
  mode,
  allowToAddDocusignTemplates,
  refetchQueries,
  onChange,
}: UseDocumentRepositoryHookProps): UseDocumentRepositoryHook => {
  const initialValues: DocumentRepositoryForm = useMemo(() => (
    { files: [], selectedFiles: [] }
  ), []);
  const uploadMutation = useMutation<UploadFiles, UploadFilesVariables>(UPLOAD_FILES, {
    refetchQueries: [FILES_QUERY, BOARDING_COMPLETENESS_QUERY, ...(refetchQueries ?? [])],
  });

  const [uploadFilesMutation, { error }] = uploadMutation;

  const uploadFiles = useCallback(async (
    { files, selectedFiles }: DocumentRepositoryForm,
    uploadCtx: FileUploadContext = {},
  ) => {
    const filesList: FileUploadInput[] = [
      // files to upload
      ...files.map((f) => ({
        ...omit(f, ['key', 'type', 'rejectionReason']),
        show_in_repository: mode === 'standalone' ? true : f.show_in_repository,
      })) as unknown as FileUploadInput[],
      // files to copy from
      ...selectedFiles.map((f) => ({
        from_file_id: f.file_id,
        name: f.name,
        category_id: f.category.category_id,
      })),
    ];

    const { data } = await uploadFilesMutation({
      variables: {
        files: filesList,
        context: uploadCtx,
      },
    });
    return data?.uploadFiles ?? [];
  }, [uploadFilesMutation, mode]);

  const form = useFormik<DocumentRepositoryForm>({
    validationSchema,
    initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: useCallback(async (values, { resetForm }) => {
      const uploadedFiles = await uploadFiles(values, uploadContext);
      onFilesUploaded?.(uploadedFiles);
      resetForm();
    }, [uploadFiles, uploadContext, onFilesUploaded]),
  });

  const { values: { files, selectedFiles } } = form;
  const totalSelectedFiles = useMemo(() => (
    files.length + selectedFiles.length
  ), [files, selectedFiles]);

  useEffect(() => {
    if (onChange) {
      onChange(files);
    }
  }, [onChange, files]);

  const hasRejectedFiles = useMemo(() => files.some((f) => !!f.rejectionReason), [files]);

  return {
    form,
    uploadContext,
    uploadFiles,
    totalSelectedFiles,
    mode,
    allowToAddDocusignTemplates,
    hasRejectedFiles,
    uploadError: error,
  };
};
