import { gql } from '@apollo/client';

export enum BidEnvelopeStatuses {
  Draft = 1,
  InProgress = 2,
  Sent = 3,
  Completed = 4,
  Waiting = 5,
  Voided = 6,
}

export const ENVELOPE_DATA_QUERY = 'EnvelopeData';
export const ENVELOPE_DATA = gql`
  query EnvelopeData($envelope_id: Int!) {
    envelope: bidEnvelope(envelope_id: $envelope_id) {
      envelope_id
      type
      sp_name
      sp_organization_id
      sp_signed_on
      fm_signed_on

      failed_on

      status: roleStatus(role: "fm") {
        status_id
        name
        icon
        color
      }

      sent_on
      sent_by
      completedOn

      spRecipients: roleRecipients (role:"sp") {
        name
        email
      }

      fmRecipients: roleRecipients (role:"fm") {
        name
        email
      }

      nextRecipient {
        recipient_id
        client_user_id
        name
        email
      }

      files {
        file_id
        file { name }
        rank
        initialed
        error_code
      }

      bids {
        bid_id
        bundle_id
        status_id
        bundle { name }
        organization { organization_id, name }
      }
    }
  }
`;

export const FOR_ENVELOPE_DATA_QUERY = 'ForEnvelopeData';
export const FOR_ENVELOPE_DATA = gql`
  query ForEnvelopeData($sp_organization_id: Int!, $bid_ids: [Int!]!) {
    bidDocuments: getBidDocuments(bid_ids: $bid_ids) {
      file_id
      name
      mime_type
      path
      url
      thumb_url
      type
      organization_id
      site_id
      bid_id
      service_id
      contract_term_id
      property_owner_id

      created_by
      created_on
      is_public

      category { category_id, name }

      bid { bid_id, bundle { name } }
      contractTerm { contract_term_id, name }
      site { site_id, name }
    }

    defaultSignatories: getBidDefaultSignatories(sp_organization_id: $sp_organization_id) {
      sp { name, dba }
      spSignatory { default_signatory_name, default_signatory_email }
      fmSignatory { default_signatory_name, default_signatory_email }
    }
  }
`;
