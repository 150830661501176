import { BrowserOptions } from '@sentry/browser';

export type EnvType = 'local' | 'dev' | 'stg' | 'demo' | 'prod';

export interface Config {
  env: EnvType;
  showDevMessages: boolean;
  apiUrl: string;
  stripe: {
    key: string;
  };
  maps: {
    key: string;
  };
  sentry: BrowserOptions;
  firebase: object;
  getStream: {
    key: string;
    appid: string;
  };
  gtm?: {
    id: string;
    auth?: string;
    preview?: string;
  };
  reports?: {
    dashboard: string;
    fm: string;
    admin: string;
  };
}

const APP_DOMAINS_TO_ENV: Record<string, EnvType> = {
  'app-dev': 'dev',
  'app-stg': 'stg',
  'app-demo': 'demo',
  app: 'prod',
};

const appDomain = window.location.hostname.split('.')[0].toLowerCase();
const envFromDomain: EnvType | undefined = APP_DOMAINS_TO_ENV[appDomain];

/**
 * Current env is calculated dynamically, with a fallback to env var and a literal value.
 */
const CURRENT_ENV: EnvType = envFromDomain || process.env.REACT_APP_STAGE || 'local';

/**
 * Stripe
 */
const STRIPE_TEST_KEY = 'pk_test_gSzRSUh7Hc65VvACf80pUj8t00hmsba6yU';
const STRIPE_LIVE_KEY = 'pk_live_GyadeC5sT6EO9cvx5RK8yLJx00nFdKwiJM';

/**
 * Google Maps
 */
const GOOGLE_MAPS_API_KEY = 'AIzaSyAcMAzsNs4I7p5LRDXoadEY2qr93BxK_YU';

/**
 * Sentry
 */
const SENTRY_DSN = 'https://dc806ad1d6234021846a1da773ea70a9@o366340.ingest.sentry.io/5167886';

/**
 * Firebase
 */
const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAcMAzsNs4I7p5LRDXoadEY2qr93BxK_YU',
  authDomain: 'procursys.firebaseapp.com',
  databaseURL: 'https://procursys.firebaseio.com',
  projectId: 'procursys',
  storageBucket: 'procursys.appspot.com',
  messagingSenderId: '410934137379',
  appId: '1:410934137379:web:d465f4bfea8e5d890bd796',
  measurementId: 'G-X6N15MZ4JS',
};

const lookerStudioParams = {
  admin: {},
  fm: { uuid: ['bid.uuid', 'sites_grouped.uuid', 'nobids.uuid'] },
  dashboard: {
    uuid: [
      'sites_per_owner.uuid',
      'average_sites_per_bundle.uuid',
      'sites_per_contract_type.uuid',
      'site_bundle_statuses.uuid',
      'sites_by_state.uuid',
      'last_month_bids.uuid',
    ],
    service_id: [
      'sites_per_owner.service_id',
      'average_sites_per_bundle.service_id',
      'sites_per_contract_type.service_id',
      'site_bundle_statuses.service_id',
      'sites_by_state.service_id',
      'last_month_bids.service_id',
    ],
  },
};
const getLookerStudioParamsJsonString = (params: Record<string, string[]>) => {
  let result = '';
  Object.entries(params).forEach(([paramName, listOfQueryNames]) => {
    listOfQueryNames.forEach((queryName) => {
      result += `${result ? ',' : ''}"${queryName}":{{${paramName}}}`;
    });
  });
  return `{${result}}`;
};

const localConfig: Config = {
  env: 'local',
  showDevMessages: true,
  apiUrl: '//localhost:3000/graphql',
  stripe: {
    key: STRIPE_TEST_KEY,
  },
  maps: {
    key: GOOGLE_MAPS_API_KEY,
  },
  sentry: {
    dsn: SENTRY_DSN,
    enabled: false,
    environment: CURRENT_ENV,
  },
  firebase: FIREBASE_CONFIG,
  getStream: {
    key: process.env.REACT_APP_GETSTREAM_KEY || '',
    appid: process.env.REACT_APP_GETSTREAM_APPID || '',
  },
};

const devConfig: Config = {
  env: 'dev',
  showDevMessages: true,
  apiUrl: '//api-dev.procursys.com/graphql',
  stripe: {
    key: STRIPE_TEST_KEY,
  },
  maps: {
    key: GOOGLE_MAPS_API_KEY,
  },
  sentry: {
    dsn: SENTRY_DSN,
    enabled: true,
    environment: CURRENT_ENV,
  },
  firebase: FIREBASE_CONFIG,
  getStream: {
    key: 'ekjp2vcu3wb8',
    appid: '1165202',
  },
};

const stgConfig: Config = {
  env: 'stg',
  showDevMessages: false,
  apiUrl: '//api-stg.procursys.com/graphql',
  stripe: {
    key: STRIPE_TEST_KEY,
  },
  maps: {
    key: GOOGLE_MAPS_API_KEY,
  },
  sentry: {
    dsn: SENTRY_DSN,
    enabled: true,
    environment: CURRENT_ENV,
  },
  firebase: FIREBASE_CONFIG,
  getStream: {
    key: '3jt38e3chg6h',
    appid: '1165201',
  },
  reports: {
    dashboard: `https://lookerstudio.google.com/embed/reporting/8aa9739f-a32a-4d9e-a19a-363c088abdbe/page/p_etkjctkecd?params=${
      getLookerStudioParamsJsonString(lookerStudioParams.dashboard)}`,
    admin: 'https://lookerstudio.google.com/embed/reporting/abae0287-34be-4e1e-aa59-5e29341f34f2/page/L7JjD',
    fm: `https://lookerstudio.google.com/embed/reporting/768407eb-02f9-44d2-9074-ae791239ed9d/page/L7JjD?params=${
      getLookerStudioParamsJsonString(lookerStudioParams.fm)}`,
  },
};

const demoConfig: Config = {
  env: 'demo',
  showDevMessages: false,
  apiUrl: '//api-demo.procursys.com/graphql',
  stripe: {
    key: STRIPE_TEST_KEY,
  },
  maps: {
    key: GOOGLE_MAPS_API_KEY,
  },
  sentry: {
    dsn: SENTRY_DSN,
    enabled: true,
    environment: CURRENT_ENV,
  },
  firebase: FIREBASE_CONFIG,
  getStream: {
    key: 'uezwafr3e5pv',
    appid: '1165204',
  },
};

const prodConfig: Config = {
  env: 'prod',
  showDevMessages: false,
  apiUrl: '//api.procursys.com/graphql',
  stripe: {
    key: STRIPE_LIVE_KEY,
  },
  maps: {
    key: GOOGLE_MAPS_API_KEY,
  },
  sentry: {
    dsn: SENTRY_DSN,
    enabled: true,
    environment: CURRENT_ENV,
  },
  firebase: FIREBASE_CONFIG,
  getStream: {
    key: 'a659d6a5jx86',
    appid: '1165110',
  },
  gtm: { id: 'GTM-PXSJ8CN' },
  reports: {
    dashboard: `https://lookerstudio.google.com/embed/reporting/78149bf4-24b2-4d8e-ac5f-685830af9110/page/p_etkjctkecd?params=${
      getLookerStudioParamsJsonString(lookerStudioParams.dashboard)}`,
    admin: 'https://lookerstudio.google.com/embed/reporting/5c1cc337-fd0e-4956-985e-0e5e893dc927/page/L7JjD',
    fm: `https://lookerstudio.google.com/embed/reporting/56c0ce6b-5a32-4200-af80-0c78d239cfd3/page/L7JjD?params=${
      getLookerStudioParamsJsonString(lookerStudioParams.fm)}`,
  },
};

function getConfig() {
  switch (CURRENT_ENV) {
    case 'local': return localConfig;
    case 'dev': return devConfig;
    case 'stg': return stgConfig;
    case 'demo': return demoConfig;
    case 'prod': return prodConfig;
    default: throw new Error(`Unknown environment: ${CURRENT_ENV}`);
  }
}

export const config = getConfig();
