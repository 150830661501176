import React from 'react';
import clsx from 'clsx';
import { ApolloError } from '@apollo/client';
import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme, Typography, Tooltip,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { Loading } from './Loading';
import { ErrorMessage } from './notifications/ErrorMessage';

const useStyles = makeStyles((theme) => createStyles({
  actionsLeft: {},
  spacer: {
    flexGrow: 1,
  },
  dialogTitle: {
    padding: theme.spacing(1, 2, 1, 4),
  },
  disableContentPaddings: {
    padding: 0,
  },
  disableContentVerticalScrolling: {
    width: '100%',
    overflowX: 'hidden',
  },
  disableContentYScrolling: {
    overflowY: 'hidden',
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
}));

export interface CustomDialogProps extends Omit<DialogProps, 'title'> {
  id: string;
  title?: JSX.Element | string;
  titleTooltip?: boolean;
  open: boolean;
  loading: boolean;
  showLoadingSpinner?: boolean;
  error?: ApolloError | string;
  onClose: () => void;
  actions?: JSX.Element;
  actionsLeft?: JSX.Element;
  closeButtonTitle?: string;
  closeButtonLast?: boolean;
  disableContentPaddings?: boolean;
  disableContentVerticalScrolling?: boolean;
  disableContentYScrolling?: boolean;
}

export const CustomDialog: React.FC<CustomDialogProps> = ({
  id,
  title,
  titleTooltip,
  open,
  loading,
  showLoadingSpinner = true,
  error,
  onClose,
  actions,
  actionsLeft,
  closeButtonTitle,
  closeButtonLast = false,
  children,
  fullScreen,
  disableContentPaddings,
  disableContentVerticalScrolling,
  disableContentYScrolling,
  ...props
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const labelId = `${id}-dialog-title`;

  const CloseButton = () => (
    <Button variant="text" onClick={onClose} classes={{ root: classes.closeButton }}>
      {closeButtonTitle || 'Close'}
    </Button>
  );

  const pureTitleElm = (
    <Typography variant="inherit" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
      {title}
    </Typography>
  );
  const titleElm = titleTooltip ? (
    <Tooltip title={title} arrow placement="top">
      {pureTitleElm}
    </Tooltip>
  ) : pureTitleElm;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={labelId}
      fullScreen={fullScreen !== undefined ? fullScreen : !upSm}
      disableEnforceFocus // To make CKEditor popups work (e.g. link editing popup)
      {...props}
    >
      {title && (
        <DialogTitle id={labelId} classes={{ root: classes.dialogTitle }}>
          <Grid
            component="span"
            container
            direction="row"
            spacing={1}
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid component="span" item overflow="hidden">
              {titleElm}
            </Grid>
            <Grid component="span" item>
              <IconButton
                aria-label="close"
                onClick={onClose}
                color="default"
                size="large"
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent
        dividers
        classes={{
          root: clsx({
            [classes.disableContentYScrolling]: disableContentYScrolling,
            [classes.disableContentPaddings]: disableContentPaddings,
            [classes.disableContentVerticalScrolling]: disableContentVerticalScrolling,
          }),
        }}
      >
        {loading && showLoadingSpinner && <Loading center />}
        {error && (
          <ErrorMessage center devMessage={typeof error === 'string' ? error : error.message}>
            Failed loading data
          </ErrorMessage>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {actionsLeft && (
          <>
            <Box className={classes.actionsLeft} ml={1}>
              {actionsLeft}
            </Box>
            <div className={classes.spacer}>&nbsp;</div>
          </>
        )}
        {!closeButtonLast && <CloseButton />}
        {actions}
        {closeButtonLast && <CloseButton />}
      </DialogActions>
    </Dialog>
  );
};
